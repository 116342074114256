import * as React from 'react'

export const PerformanceInsight = (props: any) =>
<svg viewBox="0 0 44 44" fill="none" width={props.svgwidth} height={props.svgwidth} xmlns="http://www.w3.org/2000/svg">
  <path
    d="M36.75 14.3957V11.8332C36.75 10.927 36.39 10.058 35.7493 9.41722C35.1085 8.77647 34.2395 8.4165 33.3333 8.4165H9.41667C8.51051 8.4165 7.64147 8.77647 7.00072 9.41722C6.35997 10.058 6 10.927 6 11.8332V35.7498C6 36.656 6.35997 37.525 7.00072 38.1658C7.64147 38.8065 8.51051 39.1665 9.41667 39.1665H15.3958"
    stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M28.2084 5V11.8333" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M14.5416 5V11.8333" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 18.6665H14.5417" stroke="#1E4E69" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path
    d="M35.4668 22.4682L32.8947 20H40V26.8182L37.4563 24.3773L27.2105 34.1273L22.2795 30.0091L16.9079 35H13L22.1942 26.3545L27.2105 30.4182"
    fill="#26A499"/>
</svg>